import { red } from '@material-ui/core/colors';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

const playfair = {
  fontFamily: 'Playfair Display',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

// A custom theme for this app
let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#896e45',
      contrastText: '#fff',
    },
    secondary: {
      main: '#31486f',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#dedede',
    },
  },
  typography: {
    fontFamily: [
      'Playfair Display',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [playfair],
      },
    },
  },
});
theme = responsiveFontSizes(theme);

export default theme;
