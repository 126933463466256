// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-baurecht-js": () => import("./../../../src/pages/baurecht.js" /* webpackChunkName: "component---src-pages-baurecht-js" */),
  "component---src-pages-beratung-fuer-kommunen-js": () => import("./../../../src/pages/beratung-fuer-kommunen.js" /* webpackChunkName: "component---src-pages-beratung-fuer-kommunen-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kanzlei-js": () => import("./../../../src/pages/kanzlei.js" /* webpackChunkName: "component---src-pages-kanzlei-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-rechtsgebiete-js": () => import("./../../../src/pages/rechtsgebiete.js" /* webpackChunkName: "component---src-pages-rechtsgebiete-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-verkehrsrecht-js": () => import("./../../../src/pages/verkehrsrecht.js" /* webpackChunkName: "component---src-pages-verkehrsrecht-js" */),
  "component---src-templates-rechtsgebiet-template-js": () => import("./../../../src/templates/rechtsgebiet-template.js" /* webpackChunkName: "component---src-templates-rechtsgebiet-template-js" */)
}

